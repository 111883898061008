import axios from "axios";

// 创建一个 axios实例
const apiClient = axios.create({
    baseURL: 'https://api.timelord.pro',
    // baseURL: 'http://192.168.1.135:3001',
    timeout: 5000,
})

const ApiPlugin = {
    install(Vue) {
      Vue.prototype.$api = {
        async get(url, params) {
          return await apiClient.get(url, { params })
            .then(response => response.data)
            .catch(error => {
              
              throw error;
            });
        },
  
        async post(url, data) {
          return await apiClient.post(url, data)
            .then(response => response.data)
            .catch(error => {
              throw error;
            });
        },
      };
    },
  };

  export default ApiPlugin;