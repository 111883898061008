import BigNumber from "bignumber.js";
// web3 公共库
export default class fedtIdoMethod{

    constructor(contract,abi,contractAddress,userAddress){
        this.contract = contract;
        this.abi = abi;
        this.contractAddress = contractAddress;
        this.userAddress = userAddress;
        if(this.contract && this.abi && this.contractAddress && this.userAddress){
            this.methods = new this.contract.Contract(this.abi,this.contractAddress,{from: this.userAddress}).methods
        }
    }
    
    // 获取用户是否参与私募
    async getUserIsIdo(userAddress){
        try {
            let result =  await this.methods.getUserIdoInfo(userAddress).call();
            if(result){
                return result
            }
        } catch(err){
            console.log(err)
        }
    }

    // 获取节点剩余份额
    async getQuota(){
        try { 
            let result =  await this.methods.getQuota().call();
            if(result){
                result = new BigNumber(result).div(new BigNumber(10).pow(0)).toNumber();
                return result;
            }
        } catch(err){
            console.log(err)  
        }
    }

    // 获取当前私募fedt数量与及在第几轮私募
    async getAmount() {
        try {
            let result = await this.methods.getTotalTokenAmount().call();
            if(result) {
                result = new BigNumber(result).div(new BigNumber(10).pow(6)).toNumber();
                return result;
            } else {
                return Number(result);
            }
        } catch(err) {
            console.log(err)
        }
    }

    // 获取用户购买了多少USDT的fedt
    async getUserBuyMany(address) {
        try {
            let result = await this.methods.getUserIdoInfo(address).call();
            if(result) {
                result = result;
                return result;
            } else {
                return result ;
            }
        } catch(err) {
            console.log(err)
        }
    }

    // 参与IDO
    async buyProduct(type, topAddress){
        try {
            let result =  await this.methods.buyIDOProduct(type, topAddress).send();
            if(result){
                return result;
            }
        } catch(err){
            console.log(err)
        }
    }


    // 获取节点剩余名额
    async getNodeNum(vip) {
        try {
            let result =  await this.methods.getVipProductInfo(vip).call();
            if(result){
                return Number(result.quota);
            }
        } catch(err){
            console.log(err)
        }
    }

    // 参与sto
    async buySto(type, topAddress){
        try {
            let result =  await this.methods.buyVipProduct(type, topAddress).send();
            if(result){
                return result;
            }
        } catch(err){
            console.log(err)
        }
    }
} 