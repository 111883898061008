<template>
    <div class="home">
        <homeHead :isLoading="isLoading"></homeHead>
        <div class="index-box">
            <div
                class="index-tabs animate__animated animate__fadeInUpBig animate__delay-0.4s"
            >
                <div class="index-tabs-li">交易所</div>
                <div class="index-tabs-li" @click="btn">DeFi钱包</div>
            </div>
            <div class="sto-swipe animate__animated animate__backInLeft animate__delay-1s" style="margin-top: 20px;">
                <van-swipe
                    class="my-swipe"
                    :autoplay="3000"
                    indicator-color="white"
                >
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image1.png"
                        />
                    </van-swipe-item>
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image2.png"
                        />
                    </van-swipe-item>
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image3.png"
                        />
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div
                style="margin: 10px 0 20px 10px"
                class="animate__animated animate__backInRight animate__delay-2s"
            >
                <van-notice-bar
                    background="#fff"
                    color="#767F8C"
                    left-icon="volume-o"
                    mode="link"
                    text="Swap2.0～STO节点招募系统现已全面开放！Swap2.0～STO节点招募系统现已全面开放！Swap2.0～STO节点招募系统现已全面开放！"
                />
            </div>
            <div
                class="index-center animate__animated animate__fadeInUpBig animate__delay-3s"
            >
                <div class="index-center-li" @click="sm">
                    <img src="./img/sm.png" />
                    私募
                </div>
                <div class="index-center-li" @click="sto">
                    <img src="./img/sto.png" />
                    STO
                </div>
                <div class="index-center-li" @click="btn">
                    <img src="./img/zywk.png"/>
                    质押挖矿
                </div>
                <div class="index-center-li" @click="btn">
                    <img src="./img/nft.png"/>
                    NFT
                </div>
                <div class="index-center-li" @click="btn">
                    <img src="./img/swap.png"/>
                    Swap
                </div>
            </div>
            <div
                class="index-center animate__animated animate__fadeInUpBig animate__delay-3s"
            >
                <div class="index-center-li" @click="$router.push('/statistics')">
                    <img src="./img/zc.png" />
                    资产
                </div>
                <div class="index-center-li" @click="btn">
                    <img src="./img/zy.png"/>
                    主流币质押
                </div>
                <div class="index-center-li" @click="btn">
                    <img src="./img/lh.png"/>
                    量化交易
                </div>
                <div class="index-center-li" @click="btn">
                    <img src="./img/xy.png"/>
                    商学院
                </div>
                <div class="index-center-li" @click="btn">
                    <img src="./img/hyyq.png"/>
                    邀请好友
                </div>
            </div>
        </div>
        <div class="index-zf">
            <div class="index-zf-list animate__animated animate__fadeInUpBig animate__delay-4s">
                <div class="index-zf-li" @click="btn">
                    <span>自选</span>
                </div>
                <div class="index-zf-li" @click="btn">热门</div>
                <div class="index-zf-li" @click="btn">涨幅榜</div>
                <div class="index-zf-li" @click="btn">跌幅榜</div>
                <div class="index-zf-li" @click="btn">新币</div>
            </div>
            <div class="index-zf-list animate__animated animate__zoomIn animate__delay-4s">
                <div class="index-zf-tiem">币种</div>
                <div class="index-zf-tiem">最新价格</div>
                <div class="index-zf-tiem">24小时涨幅</div>
            </div>
            <div class="index-jz animate__animated animate__zoomInDown animate__delay-4s">
                <div class="index-jz-li">
                    <div class="index-jz-li-img"></div>
                    <div class="index-jz-li-bz">FED</div>
                </div>
                <div class="index-jz-li">20,451.0</div>
                <div class="index-jz-li">
                    <span class="index-jz-li-color">+3.00%</span>
                </div>
            </div>
            <div class="index-jz animate__animated animate__zoomInDown animate__delay-4s">
                <div class="index-jz-li">
                    <div class="index-jz-li-img"></div>
                    <div class="index-jz-li-bz">BNB</div>
                </div>
                <div class="index-jz-li">331.3</div>
                <div class="index-jz-li">
                    <span class="index-jz-li-color">+0.01%</span>
                </div>
            </div>
            <div class="index-jz animate__animated animate__zoomInDown animate__delay-5s">
                <div class="index-jz-li">
                    <div class="index-jz-li-img"></div>
                    <div class="index-jz-li-bz">BTC</div>
                </div>
                <div class="index-jz-li">27,661.00</div>
                <div class="index-jz-li">
                    <span class="index-jz-li-color1">-1.01%</span>
                </div>
            </div>
            <div class="index-jz animate__animated animate__zoomInDown animate__delay-5s">
                <div class="index-jz-li">
                    <div class="index-jz-li-img"></div>
                    <div class="index-jz-li-bz">USDT</div>
                </div>
                <div class="index-jz-li">1.0043</div>
                <div class="index-jz-li">
                    <span class="index-jz-li-color">+0.01%</span>
                </div>
            </div>
            <div class="index-jz animate__animated animate__zoomInDown animate__delay-5s">
                <div class="index-jz-li">
                    <div class="index-jz-li-img"></div>
                    <div class="index-jz-li-bz">FED</div>
                </div>
                <div class="index-jz-li">1.007</div>
                <div class="index-jz-li">
                    <span class="index-jz-li-color">+100.01%</span>
                </div>
            </div>
        </div>
        <div class="index-fnt">
            <div>
                <img style="width: 100%; height: 100%;" src="./img/fnt.png" @click="$router.push('/statistics')">
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { Toast } from 'vant'
import homeHead from '../components/homeHead/homeHead'
export default {
    name: 'Index',
    // 组件
    components: {
        homeHead,
    },
    data() {
        return {
            userUsdt: 0,
            isApprove: false,
            isIdo: false,
            isLoading: false,
            timer: 0,
        }
    },
    computed: {
        // text() {
        //     return this.currentRate.toFixed(0) + '%'
        // },
    },
    async created() {
        // console.log(await this.basicWallet.getContract('zhu'))
        // console.log(await this.basicWallet.someMethod('zhu'))
        // console.log(this.someMethod)
        // let res = await new basicWallet().getContract('zhu')
        // console.log(await res.xz("xxxxxx",'x'))
        // let res = await new basicWallet().getContract('pancakeRouterContract')
        // this.s = await res.getBalance()
        // console.log(await res.getBalance())
        // this.$api.get('/app/token/getUserInfos',{
        //     0: '0xE329b52E1F8c632B2Afec04CEDEaF83a512a10c5',
        //     1: 'NULL'
        // })
        //     .then((data) => {
        //         console.log('GET request response:', data)
        //     })
        //     .catch((error) => {
        //         console.error('GET request error:', error)
        //     })
        // if(this.web3) {
        //     console.log(this.web3, "address");
        //     let usdtContract = await this.basicWallet.getContract("usdtTokenContract");
        //     if (usdtContract) {
        //         // 判断用户usdt是否授权
        //         let usdtApprove = await usdtContract.getIsApprove(this.web3.userAddress,this.web3.hjtapproveProxyContractAddress);
        //         if (usdtApprove) {
        //             console.log(usdtApprove, "approve");
        //             this.isApprove = "已授权";
        //         } else {
        //             this.isApprove = "未授权";
        //         }
        //         // 获取用户usdt余额
        //         let usdtBalance = await usdtContract.getBalance(this.web3.userAddress,this.web3.lpTokenDecimal);
        //         if (usdtBalance) {
        //             this.userUsdt = Number(usdtBalance).toFixed(3);
        //             console.log(usdtBalance, "balance");
        //         }
        //     }
        //     let totasplly = await this.basicWallet.getContract("hjtTokenContract");
        //     if(totasplly) {
        //         let tokentotasplly = await totasplly.getTotalSupply(6);
        //         console.log(tokentotasplly,"hjtTokenContract");
        //     }
        // }
        // var now = new Date().getTime()
        // this.timer = 1689420600000 - now
        // console.log(this.timer)
    },
    methods: {
        btn() {
            return Toast.fail("正在开发，敬请期待")
        },
        // 跳转STO
        async sto() {
            this.$router.push('/sto')
        },
        // 跳转私募
        async sm() {
            this.$router.push('/Ido')
        },
        // 授权usdt
        async approveUsdt() {
            this.isLoading = true
            if (this.web3) {
                let usdtContract = await this.basicWallet.getContract(
                    'usdtTokenContract'
                )
                if (usdtContract) {
                    let result = await usdtContract.approve(
                        this.web3.idoHjtDividendContractAddress
                    )
                    if (result) {
                        console.log('授权成功')
                        await setTimeout(() => {}, 1000)
                        await this.created()
                        this.isApprove = true
                        this.isLoading = false
                    } else {
                        this.isLoading = false
                        console.log('授权失败')
                    }
                }
            }
        },
        async buygft() {
            if (this.web3) {
                let pancake = await this.basicWallet.getContract(
                    'pancakeRouterContract'
                )
                if (pancake) {
                    let gftpancake = await pancake.getAmountsOut(
                        '0xA669b3f9a25258D1175a0E8F4CA6Bd7f029Fd9D5',
                        6,
                        this.web3.usdtTokenAddress,
                        18,
                        1
                    )
                    if (gftpancake) {
                        console.log(gftpancake)
                    } else {
                        console.log('获取数据失败')
                    }
                }
            }
        },

        async buygft2() {
            if (this.web3) {
                let pancake = await this.basicWallet.getContract(
                    'pancakeRouterContract'
                )
                if (pancake) {
                    let gftpancake = await pancake.getAmountsIn(
                        '0xA669b3f9a25258D1175a0E8F4CA6Bd7f029Fd9D5',
                        6,
                        this.web3.usdtTokenAddress,
                        18,
                        1
                    )
                    if (gftpancake) {
                        console.log(gftpancake)
                    } else {
                        console.log('获取数据失败')
                    }
                }
            }
        },
        // timer() {
        //     var now = new Date().getTime()
        //     this.timer = 1689420600000 - now
        //     setTimeout('timer()', 1000)
        // },
    },
}
</script>
<style>
@import './style/index.css';
</style>
