<template>
    <div class="homes">
        <homeHead :isLoading="isLoading"></homeHead>
        <div class="sto-buy">
            <history message="STO额度购买"></history>
            <div class="sto-boy-box">
                <div class="sto-box-heom">STO节点额度规则：</div>
                <div class="sto-box-li">
                    <div class="sto-box-li-color">
                        <span>认购金额: </span>
                        <span>{{ jumpIndex.u }}U</span>
                    </div>
                    <div class="sto-box-li-color">
                        <span>限定名额: </span>
                        <span>{{ jumpIndex.me }}个</span>
                    </div>
                </div>
                <div class="sto-box-li">
                    <div class="sto-box-li-color">
                        <span>赠送节点：</span>
                        <span>{{ jumpIndex.jd }}</span>
                    </div>
                    <div class="sto-box-li-color"></div>
                </div>
                <div class="sto-box-li">
                    <div class="sto-box-li-color">
                        <span>节点权益：</span>
                        <span>{{ jumpIndex.qy }}</span>
                    </div>
                    <div class="sto-box-li-color"></div>
                </div>
                <div class="sto-box-li">
                    <div class="sto-box-li-color">
                        <span>级别权益：</span>
                        <span>{{ jumpIndex.jb }}</span>
                    </div>
                    <div class="sto-box-li-color"></div>
                </div>
                <div class="sto-box-li">
                    <div class="sto-box-li-color">
                        <span>邀请奖励： </span>
                        <span>一代：{{ jumpIndex.yqyd }}</span>
                    </div>
                    <div class="sto-box-li-color">
                        <span>二代: </span>
                        <span>{{ jumpIndex.yqed }}</span>
                    </div>
                </div>
            </div>
            <div class="sto-hr">限量认购，单钱包地址最多认购1份STO节点</div>
            <div class="sto-boy-jd">
                <img style="width: ;: 190px; height: 130px;" :src = urls />
                <div class="sto-boy-jd-color">剩余名额{{ this.residueNum }}</div>
            </div>
            <!-- <div class="sto-boy-bnt">购买STO节点</div> -->
            <div v-show="!isApproveUsdt" class="sto-boy-bnt" @click="approveUsdt()">授权</div>
            <div v-show="isApproveUsdt" class="sto-boy-bnt" @click="buyStoProduct()">购买STO节点</div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import homeHead from '../components/homeHead/homeHead'
import history from '../components/historyGo/historyGo.vue'
import { Toast } from 'vant'
export default {
    name: 'stoByu1',
    // 组件
    components: {
        homeHead,
        history,
    },
    data() {
        return {
            active: 1,
            userUsdt: 0,
            isApprove: false,
            isIdo: false,
            isLoading: false,
            timer: 0,
            jumpIndex: {},
            residueNum: 0,
            urls:"",
            isApproveUsdt: false,
            userUsdt: 0,
            userIsSto: false,
            pAddress: '',
            vip:'',
        }
    },
    async mounted() {
        const res = this.$route.query // 获取传递的参数
        // console.log(res)
        this.jumpIndex = res;
        this.vip = res.vip;
        this.urls = require(`./img/${res.id}.png`)
        await this.getResidueNum(res.vip);
    },
    computed: {},
    async created(res) {
        await this.getUserIsApprove();
        await this.getUserInfo();
        await this.getUserStoInfo();
    },
    methods: {
        // 获取用户信息
        async getUserInfo() {
            this.isLoading = true;
            let userIsIdo = await this.basicWallet.getContract("fedtIdoContract");
            if(userIsIdo) {
                let getUserIsIdo = await userIsIdo.getUserIsIdo(this.web3.userAddress);
                // console.log('isido',getUserIsIdo)
                if(getUserIsIdo.level > 0) {
                    this.userIsSto = true;
                } else {
                    this.userIsSto = false;
                }
                this.isLoading = false;
            }
            this.isLoading = false;
        },
        // 
        getUserStoInfo() {
            this.$api.get('/app/token/getUserInfo',{
            address: this.web3.userAddress,
            type: 'FEDT'
            })
                .then((data) => {
                    if(data.flag == "SUCCESS" && data.payload.paddress) {
                        // console.log('GET request response:', data)
                        // this.teamChildren = data.payload.team_children;
                        this.pAddress = data.payload.paddress;
                        this.topAddress = data.payload.topAddress;
                    } 
                })
                .catch((error) => {
                    // console.error('GET request error:', error)
                })
        },
        // 获取剩余名额
        async getResidueNum(vip) {
            this.isLoading = true;
            let result = await this.basicWallet.getContract("fedtIdoContract");
            if(result) {
                let residue = await result.getNodeNum(vip);
                this.residueNum = residue;
                this.isLoading = false;
            } 
            this.isLoading = false;
        },
        // 获取用户是否授权和usdt余额
        async getUserIsApprove() {
            this.isLoading = true;
            let usdtContract = await this.basicWallet.getContract("usdtTokenContract");
            if (usdtContract) {
                // 判断用户usdt是否授权
                let usdtApprove = await usdtContract.getIsApprove(this.web3.userAddress,this.web3.fedtIdoAddress);
                if (usdtApprove) {
                    this.isApproveUsdt = true;
                } else {
                    this.isApproveUsdt = false;
                }
                // 获取用户usdt余额
                let usdtBalance = await usdtContract.getBalance(this.web3.userAddress,this.web3.lpTokenDecimal);
                if (usdtBalance) {
                    this.userUsdt = Number(usdtBalance).toFixed(3);
                }
            }
            this.isLoading = false;
        },
        // 授权usdt
        async approveUsdt() {
            this.isLoading = true;
            if(this.web3) {
                let usdtContract = await this.basicWallet.getContract("usdtTokenContract");
                if(usdtContract) {
                    let result = await usdtContract.approve(this.web3.fedtIdoAddress);
                    if(result) {
                        this.isApproveUsdt = true;
                        await setTimeout(() => {}, 1000);
                        await this.getUserIsApprove();
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                    }
                }
            }
            this.isLoading = false;
        },
        // 购买STO节点
        async buyStoProduct() {
            // console.log('type',this.jumpIndex.u,this.jumpIndex.vip)
            this.isLoading = true;
            if(!this.userIsSto) {
                if(this.pAddress != '') {
                    if(this.userUsdt >= Number(this.jumpIndex.u)) {
                        if(this.web3) {
                            let getSto = await this.basicWallet.getContract("fedtIdoContract");
                            if(getSto) {
                                if(this.topAddress == '0xa906491d1d395cab1c98095b969ef670b402632c') {
                                    let result = await getSto.buySto(this.jumpIndex.vip, 'A');
                                    if(result) {
                                        await setTimeout(() => {}, 1000);
                                        await this.getUserInfo();
                                        await this.getResidueNum();
                                        this.isLoading = false;
                                    } else {
                                        this.isLoading = false;
                                    }  
                                }
                                if(this.topAddress == '0x9e8f796d3286d3e87d24a2a9babec7e4398300cf') {
                                    let result = await getSto.buySto(this.jumpIndex.vip, 'B');
                                    if(result) {
                                        await setTimeout(() => {}, 1000);
                                        await this.getUserInfo();
                                        this.isLoading = false;
                                    } else {
                                        this.isLoading = false;
                                    }  
                                }
                            }
                        } else{
                            this.isLoading = false;
                        }
                    } else {
                        this.isLoading = false;
                        return Toast.fail("USDT余额不足")
                    }
                } else {
                    this.isLoading = false;
                    return Toast.fail("请先绑定推荐人")
                }
            } else {
                this.isLoading = false;
                return Toast.fail("不可重复购买")
            }
        }
    },
}
</script>
<style>
@import './style/sto-buy.css';
</style>
