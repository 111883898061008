import Vue from "vue"

// 导入Contract控制器
import BasicWallet from "./basicWallet";

// 文件导入 abi
import erc20Abi from "../abi/erc20_abi.json";
import uniswapAbi from "../abi/uniswap_abi.json";
import fedtIdoAbi from "../abi/fedtIdo_abi.json";

// 导入web3公共方法
import publicMethod from "../contract/publicMethod";
import uniSwap from "../contract/uniSwap";
import fedtIdoMethod from "../contract/fedtIdoMethod";

// 导入自定义方法


// 主体钱包公共方法
export default class MainWallet extends BasicWallet{
  tokenDecimal = 6;  // 主币-子币 精度
  lpTokenDecimal = 18; // LP币 精度
  usdtDecimal = 18;

  // 合约地址 
  panCakeRouterAddress = '0x10ED43C718714eb63d5aA57B78B54704E256024E'; // PANCAKE 路由地址
  usdtTokenAddress = '0x55d398326f99059fF775485246999027B3197955'; // USDT 合约地址

  fedtIdoAddress = '0x069709662560192CeD47fF3e0d5E5c649F238775';//私募合约
  fedtTokenAddres = '';//fedt本币合约

  // 构造器
//   pancakeRouterContract
  
  // 初始化合约方法
  async initOnInitFinished(data){
    let pancakeRouterContract  = await new uniSwap(data.web3.eth, uniswapAbi,this.panCakeRouterAddress,data.userAddress);
    this.setContract('pancakeRouterContract',pancakeRouterContract);

    let usdtTokenContract = await new publicMethod(data.web3.eth, erc20Abi, this.usdtTokenAddress, data.userAddress);
    this.setContract('usdtTokenContract', usdtTokenContract);

    let fedtTokenContract = await new publicMethod(data.web3.eth, erc20Abi, this.fedtTokenAddres, data.userAddress);
    this.setContract('fedtTokenContract', fedtTokenContract);

    let fedtIdoContract = await new fedtIdoMethod(data.web3.eth, fedtIdoAbi, this.fedtIdoAddress, data.userAddress);
    this.setContract('fedtIdoContract', fedtIdoContract);

  }

}