<template>
    <div class="homes">
        <homeHead :isLoading="isLoading"></homeHead>
        <history message="STO购买"></history>
        <div class="sto">
            <div class="sto-swipe" style="margin-top: 10px;">
                <van-swipe
                    class="my-swipe"
                    :autoplay="3000"
                    indicator-color="white"
                >
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image.png"
                        />
                    </van-swipe-item>
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image.png"
                        />
                    </van-swipe-item>
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image.png"
                        />
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="sto-tjr-dz">
                <van-field
                    v-model="parentAddress"
                    center
                    clearable
                    placeholder="请输入推荐人地址"
                >
                    <template #button>
                        <van-button size="small" type="primary" @click="getUserBind()"
                            >绑定推荐人</van-button
                        >
                    </template>
                </van-field>
            </div>
            <div class="sto-tab">
                <div class="sto-tab-li">
                    <van-tabs v-model="active">
                        <van-tab title="STO额度">
                            <div>
                                <div class="sto-tab-box">
                                    <div
                                        class="sto-tab-box-li"
                                        @click="jumpTba(0)"
                                    >
                                        <img
                                            style="width: 180px; height: 110px"
                                            src="./img/1.png"
                                        />
                                    </div>
                                    <div
                                        class="sto-tab-box-li"
                                        @click="jumpTba(1)"
                                    >
                                        <img
                                            style="width: 180px; height: 110px"
                                            src="./img/2.png"
                                        />
                                    </div>
                                </div>
                                <div class="sto-tab-box">
                                    <div
                                        class="sto-tab-box-li"
                                        @click="jumpTba(2)"
                                    >
                                        <img
                                            style="width: 180px; height: 110px"
                                            src="./img/3.png"
                                        />
                                    </div>
                                    <div
                                        class="sto-tab-box-li"
                                        @click="jumpTba(3)"
                                    >
                                        <img
                                            style="width: 180px; height: 110px"
                                            src="./img/4.png"
                                        />
                                    </div>
                                </div>
                                <div class="sto-tab-box">
                                    <div
                                        class="sto-tab-box-li"
                                        @click="jumpTba(4)"
                                    >
                                        <img
                                            style="width: 180px; height: 110px"
                                            src="./img/5.png"
                                        />
                                    </div>
                                    <div class="sto-tab-box-li">
                                        <!-- <img
                                            style="width: 180px; height: 110px"
                                            src="./img/sto-usdt.png"
                                        /> -->
                                    </div>
                                </div>
                            </div>
                        </van-tab>
                        <!-- <van-tab title="邀请返利">
                            <div style="height: auto">
                                <div class="sto-fl">
                                    <div class="sto-fl-box">
                                        <div class="sto-fl-box-li color">
                                            你的总邀请奖励：
                                        </div>
                                        <div class="sto-fl-box-li color">
                                            {{ Number(this.income.recommend_dividends).toFixed(2) }} USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 12px 0"
                                    >
                                        <div class="sto-fl-box-li">
                                            <span
                                                class="color1"
                                                style="padding-right: 10px"
                                                >总邀请地址:</span
                                            >
                                            <span class="color2">0</span>
                                        </div>
                                        <div class="sto-fl-box-li">
                                            <span
                                                class="color1"
                                                style="padding-right: 10px"
                                                >今日邀请:</span
                                            >
                                            <span class="color2">0</span>
                                        </div>
                                    </div>
                                    <div class="sto-fl-box">
                                        <div class="sto-fl-box-li color">
                                            其中一代邀请:
                                        </div>
                                        <div
                                            class="sto-fl-box-li color"
                                            style="text-align: center"
                                        >
                                            <span
                                                class="color1"
                                                style="padding-right: 10px"
                                                >地址:</span
                                            >
                                            <span class="color2">0</span>
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            7%推荐奖励
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            永恒V5：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：200USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            神祈V4：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：200USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            帝王V3：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：200USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            地主V2：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：200USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            矿工V1：3 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：200USDT
                                        </div>
                                    </div>

                                    <div
                                        class="sto-fl-box"
                                        style="margin: 25px 0"
                                    >
                                        <div class="sto-fl-box-li color">
                                            其中二代邀请:
                                        </div>
                                        <div
                                            class="sto-fl-box-li color"
                                            style="text-align: center"
                                        >
                                            <span
                                                class="color1"
                                                style="padding-right: 10px"
                                                >地址:</span
                                            >
                                            <span class="color2">0</span>
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            3%推荐奖励
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            永恒V5：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：100USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            神祈V4：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：100USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            帝王V3：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：100USDT
                                        </div>
                                    </div>
                                    <div
                                        class="sto-fl-box"
                                        style="margin: 15px 0"
                                    >
                                        <div class="sto-fl-box-li color1">
                                            地主V2：1 名
                                        </div>
                                        <div class="sto-fl-box-li color1">
                                            奖励：100USDT
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </van-tab> -->
                        <van-tab :disabled="true" title="">内容 2</van-tab>
                        <van-tab :disabled="true" title="">内容 2</van-tab>
                        <van-tab :disabled="true" title="">内容 2</van-tab>
                    </van-tabs>
                </div>
                <!-- <div class="sto-tab-li">1</div> -->
            </div>
            <div class="sto-nft">
                <div class="sto-nft-li">
                    <div class="sto-nft-color">我的STO</div>
                </div>
                <div class="sto-nft-li color1" @click="$router.push('/history');">历史记录</div>
            </div>
            <div class="sto-tab-box">
                <div class="sto-tab-box-li" v-show="this.level == 0">
                    <img
                        style="width: 180px; height: 110px"
                        src="./img/sto-usdt.png"
                    />
                </div>
                <div class="sto-tab-box-li" v-show="this.level == 1">
                    <img
                        style="width: 180px; height: 110px"
                        src="./img/1.png"
                    />
                </div>
                <div class="sto-tab-box-li" v-show="this.level == 2">
                    <img
                        style="width: 180px; height: 110px"
                        src="./img/2.png"
                    />
                </div>
                <div class="sto-tab-box-li" v-show="this.level == 3">
                    <img
                        style="width: 180px; height: 110px"
                        src="./img/3.png"
                    />
                </div>
                <div class="sto-tab-box-li" v-show="this.level == 4">
                    <img
                        style="width: 180px; height: 110px"
                        src="./img/4.png"
                    />
                </div>
                <div class="sto-tab-box-li" v-show="this.level == 5">
                    <img
                        style="width: 180px; height: 110px"
                        src="./img/5.png"
                    />
                </div>
            </div>
            <div style="height: 60px;"></div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import homeHead from '../components/homeHead/homeHead'
import history from '../components/historyGo/historyGo.vue'
import { Toast } from 'vant';
export default {
    name: 'Index',
    // 组件
    components: {
        homeHead,
        history,
    },
    data() {
        return {
            active: 0,
            isLoading: false,
            sms:'',
            parentAddress: '',
            level: 0,
            income:'',
            jumpIndex: [
                {
                    u: 10000,
                    vip: 'V5',
                    me: 10,
                    jd: '永恒V5',
                    qy: '平台加权分红5%',
                    jb: '团队收益的50%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金',
                    syme: 10,
                    url: './img/1.png',
                    id: 1,
                },
                {
                    u: 5000,
                    vip: 'V4',
                    me: 20,
                    jd: '神祈V4',
                    qy: '平台加权分红4%',
                    jb: '团队收益的40%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金 ',
                    syme: 20,
                    url: './img/2.png',
                    id: 2,
                },
                {
                    u: 2000,
                    vip: 'V3',
                    me: 50,
                    jd: '帝王V3',
                    qy: '平台加权分红3%',
                    jb: '团队收益的30%',
                    yqyd: '10%佣金',
                    yqed: '5 %佣金',
                    syme: 50,
                    url: './img/3.png',
                    id: 3,
                },
                {
                    u: 1000,
                    vip: 'V2',
                    me: 100,
                    jd: '地主V2',
                    qy: '平台加权分红2%',
                    jb: '团队收益的20%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金',
                    syme: 100,
                    url: './img/4.png',
                    id: 4,
                },
                {
                    u: 500,
                    vip: 'V1',
                    me: 200,
                    jd: '矿工V1',
                    qy: '平台加权分红1%',
                    jb: '团队收益的10%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金',
                    syme: 200,
                    url: './img/5.png',
                    id: 5,
                },
            ],
        }
    },
    computed: {},
    async created() {
        await this.getUserInfo();
        await this.getUserInfo();
        await this.getUserProfitInfo();
    },
    methods: {
        async jumpTba(index) {
            // console.log(index)
            // this.$router.push({
            //     name: 'stoByu1', // 目标路由的名称
            //     params: this.jumpIndex[index]
            // })
            this.$router.push({
                path: '/stoByu1',
                query: this.jumpIndex[index],
            })
        },
        // 获取用户vip等级
        async getUserInfo() {
            this.isLoading = true;
            let userIsIdo = await this.basicWallet.getContract("fedtIdoContract");
            if(userIsIdo) {
                let getUserIsIdo = await userIsIdo.getUserIsIdo(this.web3.userAddress);
                // console.log('000',getUserIsIdo,Number(getUserIsIdo.level))
                switch(Number(getUserIsIdo.level)) {
                    case 0:
                        this.level = 0;
                        break;
                    case 1:
                        this.level = 1;
                        break;
                    case 2:
                        this.level = 2;
                        break;
                    case 3:
                        this.level = 3;
                        break;
                    case 4:
                        this.level = 4;
                        break;
                    case 5:
                        this.level = 5;
                        break;
                }
                this.isLoading = false;
            }
            this.isLoading = false;
        },
        // 绑定推荐人
        async getUserBind() {
            this.isLoading = true;
            if(this.parentAddress != '') {
                if(!this.userIsBind) {
                    let signedMsg = await this.basicWallet.signMsg();
                    this.$api.post('/app/token/bindParent',{
                        parentAddress: this.parentAddress,
                        userAddress: this.web3.userAddress,
                        msg: signedMsg
                    })
                        .then((data) => {
                            if(data.payload) {
                                setTimeout(() => {}, 1000);
                                this.isLoading = false;
                            } else {
                                this.isLoading = false;
                                return Toast.fail(data.message)
                            }
                        })
                        .catch((error) => {
                            console.error('GET request error:', error)
                            this.isLoading = false;
                        })
                } else {
                    this.isLoading = false;
                    return Toast.fail("已绑定推荐人，不可重复绑定！")
                }
            } else {
                this.isLoading = false;
                return Toast.fail("推薦人地址不能為空")
            }
            this.isLoading = false;
        },
        // 获取收益数量
        getUserProfitInfo() {
            this.isLoading = true;
            this.$api.get('/app/token/getUserProfitInfo',{
               address: this.web3.userAddress,
               type: "FED"
            })
                .then((data) => {
                    if(data) {
                        // console.log('GET request response:', data);
                        this.isLoading = false;
                        // console.log('000',Number(data.payload.recommend_dividends).toFixed(3))
                        return this.income = data.payload;
                    }
                })
                .catch((error) => {
                    console.error('GET request error:', error)
                })
            this.isLoading = false;
        },
    },
}
</script>
<style>
@import './style/sto.css';
</style>
