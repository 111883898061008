<template>
    <div class="homes">
        <homeHead :isLoading="isLoading"></homeHead>
        <div class="sto-buy" style="height:  auto;">
            <history message="历史记录"></history>
            <div>
                <van-tabs v-model="active">
                    <van-tab title="购买记录">
                        <div style="height: auto">
                            <div class="sto-fl">
                                <div class="noDate" v-show="this.list.length < 1">暂无记录</div>
                                <div class="sto-fl-box" style="margin: 25px 0" v-for="(item,index) in this.list" :key="index">
                                    <div class="sto-fl-box-li color1">
                                        <div class="color">{{ item[0]}}USDT</div>
                                        <div
                                            class="color1"
                                            style="margin-top: 10px"
                                        >
                                            {{ item[1] }}
                                        </div>
                                    </div>
                                    <div class="sto-fl-box-li color1">
                                        {{ item[2] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab title="收益记录">
                        <div style="height: auto">
                            <div class="sto-fl">
                                <div class="sto-fl-box">
                                    <div class="sto-fl-box-li color1">金额</div>
                                    <div
                                        class="sto-fl-box-li color"
                                        style="text-align: center"
                                    >
                                        <span
                                            class="color1"
                                            style="padding-right: 10px"
                                            >类别</span
                                        >
                                    </div>
                                    <div class="sto-fl-box-li color1">状态</div>
                                </div>
                                
                                <div class="noDate" v-show="this.listIncome.length < 1">暂无记录</div>
                                <div class="sto-fl-box" style="margin: 25px 0"  v-for="(item,index) in this.listIncome" :key="index">
                                    <div class="sto-fl-box-li color1">
                                        <div class="color">{{ item[0] }} USDT</div>
                                        <div
                                            class="color1"
                                            style="margin-top: 10px; white-space:nowrap"
                                        >
                                            {{ item[1] }}
                                        </div>
                                    </div>
                                    <div class="sto-fl-box-li color1" style="text-align: center;">
                                        {{ item[2] }}代邀请
                                    </div>
                                    <div class="sto-fl-box-li color1">
                                        <div class="home-boer"></div>
                                        已经入账
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab title="邀请记录">
                        <div class="sto-fl">
                                <div class="sto-fl-box">
                                    <div class="sto-fl-box-li color1">直推人数：{{ this.child }}</div>
                                    <div
                                        class="sto-fl-box-li color"
                                        style="text-align: center"
                                    >
                                        <span
                                            class="color1"
                                            style="padding-right: 10px"
                                            ></span
                                        >
                                    </div>
                                    <div class="sto-fl-box-li color1">团队人数：{{ this.children }}</div>
                                </div>
                                <div class="noDate" v-show="this.listChildren.length < 1">暂无记录</div>
                                <div class="sto-fl-box" style="margin: 25px 0" v-for="(item,index) in this.listChildren" :key="index">
                                    <div class="sto-fl-box-li color1">
                                        <!-- <div class="color">1000USDT</div> -->
                                        <div
                                            class="color1"
                                            style="margin-top: 10px; white-space:nowrap"
                                        >
                                        <div class="home-boer"></div>
                                        <span> {{ item[0] }}</span>
                                            
                                        </div>
                                    </div>
                                    <!-- <div class="sto-fl-box-li color1" style="text-align: center;">
                                        一代邀请
                                    </div>
                                    <div class="sto-fl-box-li color1">
                                        xxxxxx
                                    </div> -->
                                </div>
                            </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import homeHead from '../components/homeHead/homeHead'
import history from '../components/historyGo/historyGo.vue'
export default {
    name: 'stoByu1',
    // 组件
    components: {
        homeHead,
        history,
    },
    data() {
        return {
            active: 0,
            userUsdt: 0,
            isApprove: false,
            isIdo: false,
            isLoading: false,
            timer: 0,
            jumpIndex: {},
            urls: '',
            list: [],
            child: 0,
            children: 0,
            listChildren: [],
            listIncome: [],
        }
    },
    mounted() {},
    computed: {},
    async created(res) {
        await this.getUserInfo();
        await this.getUserLog1();
        await this.getUserLog2();
        await this.getUserLog3();
    },
    methods: {
        // 获取用户直推和团队人数
        getUserInfo() {
            this.$api.get('/app/token/getUserInfo',{
            address: this.web3.userAddress,
            type: 'FEDT'
            })
                .then((data) => {
                    if(data.flag == "SUCCESS") {
                        // console.log('GET request response:', data)
                        this.child = data.payload.children;
                        this.children = data.payload.team_children;
                    } else {
                        this.userIsBind = false;
                    }
                })
                .catch((error) => {
                    // console.error('GET request error:', error)
                })
        },
        // 获取用户交易记录
        getUserLog1() {
            this.$api.get('/app/token/getUserLog',{
            address: this.web3.userAddress,
            stype: 'BUY'
            })
            .then((data) => {
                // console.log("TTTTT",data)
                if(data.payload) {
                    for(let i = 0; i < data.payload.length; i++) {
                        var newDate = new Date(+new Date(data.payload[i].createtime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
                        this.list.push([Number(data.payload[i].amount).toFixed(3),newDate,data.payload[i].type]);
                    }
                }
            })
            .catch((error) => {
                console.error('GET request error:', error)
            })
        },
        // 获取用户收益记录
        getUserLog2() {
            this.$api.get('/app/token/getUserLog',{
            address: this.web3.userAddress,
            stype: 'DIVIDENDS'
            })
            .then((data) => {
                // console.log("data2",data)
                if(data.payload) {
                    for(let i = 0; i < data.payload.length; i++) {
                        var newDate = new Date(+new Date(data.payload[i].createtime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
                        this.listIncome.push([Number(data.payload[i].amount).toFixed(3),newDate,data.payload[i].status]);
                        // console.log(this.listIncome,'9999')
                    }
                }
            })
            .catch((error) => {
                console.error('GET request error:', error)
            })
        },
        // 获取用户直推用户列表
        getUserLog3() {
            this.$api.get('/app/token/getUserLog',{
            address: this.web3.userAddress,
            stype: 'INVITE'
            })
            .then((data) => {
                // console.log("data3",data)
                if(data.payload) {
                    for(let i = 0; i < data.payload.length; i++) {
                        var newDate = new Date(+new Date(data.payload[i].createtime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
                        this.listChildren.push([data.payload[i].address,newDate,data.payload[i].type]);
                    }
                }
            })
            .catch((error) => {
                console.error('GET request error:', error)
            })
        }
    },
}
</script>
<style>
@import './style/history.css';
</style>
