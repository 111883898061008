<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name: 'HomeView',
    // 组件
    components: {
    },
    created() {
        this.$api.get('/app/token/getUserInfo',{
            address: this.web3.userAddress,
            type: 'FED'
            })
                .then((data) => {
                    // console.log('GET request response:', data)
                })
                .catch((error) => {
                    // console.error('GET request error:', error)
                });

    }
}
</script>
<style>
#app {

}
</style>
