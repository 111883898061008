<template>
    <div class="homes">
        <homeHead :isLoading="isLoading"></homeHead>
        <history message="私募"></history>
        <div class="sto">
            <div class="sto-swipe">
                <van-swipe
                    class="my-swipe"
                    :autoplay="3000"
                    indicator-color="white"
                >
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image1.png"
                        />
                    </van-swipe-item>
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image2.png"
                        />
                    </van-swipe-item>
                    <van-swipe-item>
                        <img
                            style="width: 100%; height: 100%"
                            src="./img/image3.png"
                        />
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="sto-tjr-dz">
                <van-field
                    v-model="parentAddress"
                    center
                    clearable
                    placeholder="请输入推荐人地址"
                >
                    <template #button>
                        <van-button size="small" type="primary"  @click="getUserBind()"
                            >绑定推荐人</van-button
                        >
                    </template>
                </van-field>
            </div>
            <div class="ido-box">
                <div class="ido-box-tiem">FEDT认购规则</div>
                <div class="ido-fedt">
                    <div class="ido-fedt-li">
                        <span>私募总量：</span>
                        <span class="lispan">1,000,000 FEDT</span>
                    </div>
                    <div class="ido-fedt-li">认购范围：全球</div>
                </div>
                <div class="ido-fedt-tiem">
                    认购时间：2023年12月5日-2023年12月30日截止
                </div>
                <div class="ido-fedt-tiem">
                    <span>每个钱包可自由认购金额</span>
                    <span class="lispan">$50 -$1000之间，仅限1份</span>
                </div>
                <div class="ido-fedt-tiem">上线前一天手动进入FED-swap提取</div>
                <div class="ido-fedt-tiem">所有代币可全部提取不锁仓</div>
                <img class="ido-fedt-img" src="./img/usdt-img.png" />
            </div>
            <div class="ido-fedt-sm">
                <div class="ido-box-tiems">私募数据</div>
                <div class="ido-fedt">
                    <div class="ido-fedt-li">
                        <span>已参与私募地址：</span>
                        <span class="color-span" v-show="!this.isIdo1">xxxxxxxxx</span>
                        <span class="color-span" v-show="this.isIdo1">{{ this.web3.userAddress.substring(0,6)+ '...' + this.web3.userAddress.substring(this.web3.userAddress.length - 6, this.web3.userAddress.length)  }}</span>
                    </div>
                    <div class="ido-fedt-li">
                        <span>已认购FEDT：</span>
                        <span class="color-span">{{ this.fedtNum }}</span>
                    </div>
                </div>
                <div class="ido-fedt">
                    <div class="ido-fedt-li">
                        <span>绑定钱包地址：</span>
                        <span class="color-span" v-show="!this.userIsBind">xxxxxxxxx</span>
                        <span class="color-span" v-show="this.userIsBind">{{ this.pAddress.substring(0,6)+ '...' + this.pAddress.substring(this.pAddress.length - 6, this.pAddress.length) }}</span>
                    </div>
                    <div class="ido-fedt-li">
                        <span>推广节点总数：</span>
                        <span class="color-span">{{ this.teamChildren }}</span>
                    </div>
                </div>
            </div>
            <div class="ido-fedt-sm">
                <div>
                    <van-steps :active="active" active-icon="success">
                        <van-step>第一轮</van-step>
                        <van-step>第二轮</van-step>
                        <van-step>第三轮</van-step>
                    </van-steps>
                </div>
                <div class="FEDT">{{ this.totalNum }}FEDT</div>
                <!-- 第一轮 -->
                <div class="ido-fedt-buy" v-show="this.active == 0">
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img100.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">455FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.22</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(100,'TypeA')">购买</div>
                    </div>
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img300.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">1430FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.21</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(300,'TypeB')">购买</div>
                    </div>
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img500.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">2500FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.20</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(500,'TypeC')">购买</div>
                    </div>
                </div>
                <!-- 第二轮 -->
                <div class="ido-fedt-buy" v-show="this.active == 1">
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img100.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">385FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.26</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(100,'TypeA')">购买</div>
                    </div>
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img300.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">1200FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.25</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(300,'TypeB')">购买</div>
                    </div>
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img500.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">2084FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.24</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(500,'TypeC')">购买</div>
                    </div>
                </div>
                <div class="ido-fedt-buy" style="width: 33%; margin-top: 12px;" v-show="this.active == 1">
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img1000.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">4348FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.23</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(1000,'TypeD')">购买</div>
                    </div>
                </div>
                <!-- 第三轮 -->
                <div class="ido-fedt-buy" v-show="this.active == 2">
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img100.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">334FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.30</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(100,'TypeA')">购买</div>
                    </div>
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img300.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">1035FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.29</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(300,'TypeB')">购买</div>
                    </div>
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img500.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">1786FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.28</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(500,'TypeC')">购买</div>
                    </div>
                </div>
                <div class="ido-fedt-buy" style="width: 33%; margin-top: 12px;" v-show="this.active == 2">
                    <div class="ido-fedt-buy-li">
                        <img src="./img/img1000.png">
                        <div class="ido-fedt-buy-font">
                            <span>获得</span>
                            <span class="ido-fedt-but-color">3704FEDT</span>
                        </div>
                        <div class="ido-fedt-buy-font">
                            <span>价格</span>
                            <span class="ido-fedt-but-color">$0.27</span>
                        </div>
                        <!-- <div class="ido-fedt-bnt-color">购买</div> -->
                        <div v-show="!isApproveUsdt" class="ido-fedt-bnt-color" @click="approveUsdt()">授权</div>
                        <div v-show="isApproveUsdt" class="ido-fedt-bnt-color" @click="getIdo1(1000,'TypeD')">购买</div>
                    </div>
                </div>
            </div>

            <div class="ido-fedt-sm">
                <div class="ido-box-tiems">我的私募</div>
                <div class="ido-fedt">
                    <div class="ido-fedt-li" style="width: 70%; line-height: 26px;">
                        <span>已购买：</span>
                        <!-- <span class="color-span">0.000 FEDT</span> -->
                    </div>
                    <div class="ido-fedt-li" style="width: 30%;">
                        <span class="bnt-color" @click="$router.push('/history');">购买记录</span>
                    </div>
                </div>
                <div class="ido-fedt-buy" style="width: 33%;">
                    <div class="ido-fedt-buy-li" v-show="this.userBuy == 100">
                        <img src="./img/img100.png">
                    </div>
                    <div class="ido-fedt-buy-li" v-show="this.userBuy == 300">
                        <img src="./img/img300.png">  
                    </div>
                    <div class="ido-fedt-buy-li" v-show="this.userBuy == 500">
                        <img src="./img/img500.png">
                    </div>
                    <div class="ido-fedt-buy-li" v-show="this.userBuy == 1000">
                        <img src="./img/img500.png">
                    </div>
                    <div class="ido-fedt-buy-li" v-show="this.userBuy == 1">
                        <img src="./img/img1000.png">
                    </div>
                </div>
                <div class="ido-fedt">
                    <div class="ido-fedt-li" style="width: 70%; line-height: 26px;">
                        <span>可提取FEDT数量：</span>
                        <span class="color-span">{{ this.fedtNum }} FEDT</span>
                    </div>
                    <div class="ido-fedt-li" style="width: 30%;">
                        <span class="bnt-color">提取代币</span>
                    </div>
                </div>
                <div class="ido-fedt">
                    <div class="ido-fedt-li" style="width: 70%; line-height: 26px;">
                        <span>获得收益：</span>
                        <span class="color-span">${{ Number(this.income.recommend_dividends).toFixed(3) }}</span>
                    </div>
                    <div class="ido-fedt-li" style="width: 30%;">
                        <span class="bnt-color">提取收益</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import homeHead from '../components/homeHead/homeHead'
import history from '../components/historyGo/historyGo.vue'
import { Toast } from 'vant';
import BigNumber from 'bignumber.js';
export default {
    name: 'Index',
    // 组件
    components: {
        homeHead,
        history,
    },
    data() {
        return {
            active: 0,
            isLoading: false,
            parentAddress: '',              // 绑定推荐人的地址
            userIsBind: false,              // 是否绑定推荐人 
            pAddress: '',                   // 用户的邀请人地址
            isIdo1: false,                  // 用户是否参与第一轮私募
            teamChildren: 0,                // 团队人数
            isApproveUsdt: false,
            userUsdt: 0,
            topAddress: '',
            isIdo1: false,
            totalNum: 0,//私募剩余数量
            userBuy: 0,//购买的产品等级
            income:'',//用户收益
            fedtNum: 0,
            jumpIndex: [
                {
                    u: 10000,
                    me: 10,
                    jd: '永恒V5',
                    qy: '平台加权分红5%',
                    jb: '团队收益的50%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金',
                    syme: 10,
                    url: './img/1.png',
                    id: 1,
                },
                {
                    u: 5000,
                    me: 20,
                    jd: '神祈V4',
                    qy: '平台加权分红4%',
                    jb: '团队收益的40%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金 ',
                    syme: 20,
                    url: './img/2.png',
                    id: 2,
                },
                {
                    u: 2000,
                    me: 50,
                    jd: '帝王V3',
                    qy: '平台加权分红3%',
                    jb: '团队收益的30%',
                    yqyd: '10%佣金',
                    yqed: '5 %佣金',
                    syme: 50,
                    url: './img/3.png',
                    id: 3,
                },
                {
                    u: 10000,
                    me: 100,
                    jd: '地主V2',
                    qy: '平台加权分红2%',
                    jb: '团队收益的20%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金',
                    syme: 100,
                    url: './img/4.png',
                    id: 4,
                },
                {
                    u: 10000,
                    me: 200,
                    jd: '矿工V1',
                    qy: '平台加权分红1%',
                    jb: '团队收益的10%',
                    yqyd: '10%佣金',
                    yqed: '5%佣金',
                    syme: 200,
                    url: './img/5.png',
                    id: 5,
                },
            ],
        }
    },
    computed: {},
    async created() {
        await this.getUserInfo();
        await this.getUserIsApprove();
        await this.getIsIdo1();
        await this.getManyIdo();
        await this.getUserBuyNum();
        await this.getUserProfitInfo();
    },
    methods: {
        async jumpTba(index) {
            // console.log(index)
            // this.$router.push({
            //     name: 'stoByu1', // 目标路由的名称
            //     params: this.jumpIndex[index]
            // })
            this.$router.push({
                path: '/stoByu1',
                query: this.jumpIndex[index],
            })
        },
        // 获取用户信息（必须一进来就加载）
        getUserInfo() {
            this.$api.get('/app/token/getUserInfo',{
            address: this.web3.userAddress,
            type: 'FEDT'
            })
                .then((data) => {
                    if(data.flag == "SUCCESS" && data.payload.paddress) {
                        // console.log('GET request response:', data)
                        this.userIsBind = true;
                        this.teamChildren = data.payload.team_children;
                        this.pAddress = data.payload.paddress;
                        this.topAddress = data.payload.topAddress;
                    } else {
                        this.userIsBind = false;
                    }
                })
                .catch((error) => {
                    // console.error('GET request error:', error)
                })
        },
        // 绑定推荐人
        async getUserBind() {
            this.isLoading = true;
            if(this.parentAddress != '') {
                if(!this.userIsBind) {
                    let signedMsg = await this.basicWallet.signMsg();
                    this.$api.post('/app/token/bindParent',{
                        parentAddress: this.parentAddress,
                        userAddress: this.web3.userAddress,
                        msg: signedMsg
                    })
                        .then((data) => {
                            if(data.payload) {
                                setTimeout(() => {}, 1000);
                                this.getUserInfo();
                                this.isLoading = false;
                            } else {
                                this.isLoading = false;
                                return Toast.fail(data.message)
                            }
                        })
                        .catch((error) => {
                            console.error('GET request error:', error)
                            this.isLoading = false;
                        })
                } else {
                    this.isLoading = false;
                    return Toast.fail("已绑定推荐人，不可重复绑定！")
                }
            } else {
                this.isLoading = false;
                return Toast.fail("推薦人地址不能為空")
            }
            this.isLoading = false;
        },
        // 获取用户是否授权和usdt余额
        async getUserIsApprove() {
            this.isLoading = true;
            let usdtContract = await this.basicWallet.getContract("usdtTokenContract");
            if (usdtContract) {
                // 判断用户usdt是否授权
                let usdtApprove = await usdtContract.getIsApprove(this.web3.userAddress,this.web3.fedtIdoAddress);
                if (usdtApprove) {
                    this.isApproveUsdt = true;
                } else {
                    this.isApproveUsdt = false;
                }
                // 获取用户usdt余额
                let usdtBalance = await usdtContract.getBalance(this.web3.userAddress,this.web3.lpTokenDecimal);
                if (usdtBalance) {
                    this.userUsdt = Number(usdtBalance).toFixed(3);
                }
            }
            this.isLoading = false;
        },
        // 授权usdt
        async approveUsdt() {
            this.isLoading = true;
            if(this.web3) {
                let usdtContract = await this.basicWallet.getContract("usdtTokenContract");
                if(usdtContract) {
                    let result = await usdtContract.approve(this.web3.fedtIdoAddress);
                    if(result) {
                        this.isApproveUsdt = true;
                        await setTimeout(() => {}, 1000);
                        await this.getUserIsApprove();
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                    }
                }
            }
            this.isLoading = false;
        },
        // 获取当前私募出去fedt数量以及第几轮私募
        async getManyIdo() {
            this.isLoading = true;
            let totalAmount = await this.basicWallet.getContract("fedtIdoContract");
            if(totalAmount) {
                let amount = await totalAmount.getAmount();
                // console.log('amount',amount)
                if(Number(amount) <= 200000) {
                    this.totalNum = 200000 - Number(amount);
                    this.active = 0;
                } else if(Number(amount) > 200000 && Number(amount) <= 500000) {
                    this.totalNum = 500000 - Number(amount);
                    this.active = 1;
                } else if(Number(amount) > 500000 && Number(amount) <= 1000000) {
                    this.totalNum = 1000000 - Number(amount);
                    this.active = 2;
                }
                this.isLoading = false;
            } 
            this.isLoading = false;
        },
        // 获取用户购买了多少USDT的产品  
        async getUserBuyNum() {
            this.isLoading = true;
            let result = await this.basicWallet.getContract("fedtIdoContract");
            if(result) {
                let getUserBuyNum = await result.getUserBuyMany(this.web3.userAddress);
                if(getUserBuyNum) {
                    // console.log('ttt',getUserBuyNum)
                    this.fedtNum = new BigNumber(getUserBuyNum.tokenAmount).div(new BigNumber(10).pow(6)).toNumber();
                    switch(getUserBuyNum.level) {
                        case 0:
                                this.userBuy = 1;
                            break;
                        case 1:
                                this.userBuy = 100;
                            break;
                        case 2:
                                this.userBuy = 300;
                            break;
                        case 3:
                                this.userBuy = 500;
                            break;
                        case 4:
                                this.userBuy = 1000;
                            break;
                    }
                }
            }
            this.isLoading = false;
        },
        // 获取用户是否参与私募
        async getIsIdo1() {
            this.isLoading = true;
            let userIsIdo = await this.basicWallet.getContract("fedtIdoContract");
            if(userIsIdo) {
                let getUserIsIdo = await userIsIdo.getUserIsIdo(this.web3.userAddress);
                // console.log('isido',getUserIsIdo)
                if(getUserIsIdo) {
                    this.isIdo1 = getUserIsIdo.isIdo;
                } else {
                    this.isIdo1 = false;
                }
            }
            this.isLoading = false;
        },
        // 参与私募
        async getIdo1(Num, type) {
            this.isLoading = true;
            if(!this.isIdo1) {
                if(this.pAddress != '') {
                    if(this.userUsdt >= Number(Num)) {
                        if(this.web3) {
                            let getIdo = await this.basicWallet.getContract("fedtIdoContract");
                            if(getIdo) {
                                if(this.topAddress == '0xa906491d1d395cab1c98095b969ef670b402632c') {
                                    let result = await getIdo.buyProduct(type, 'A');
                                    if(result) {
                                        this.isIdo1 = true;
                                        await setTimeout(() => {}, 1000);
                                        await this.getIsIdo1();
                                        this.isLoading = false;
                                    } else {
                                        this.isLoading = false;
                                    }  
                                }
                                if(this.topAddress == '0x9e8f796d3286d3e87d24a2a9babec7e4398300cf') {
                                    let result = await getIdo.buyProduct(type, 'B');
                                    if(result) {
                                        this.isIdo1 = true;
                                        await setTimeout(() => {}, 1000);
                                        await this.getIsIdo1();
                                        await this.getManyIdo();
                                        this.isLoading = false;
                                    } else {
                                        this.isLoading = false;
                                    }  
                                }
                            } else {
                                this.isLoading = false;
                            }
                        }
                    } else {
                        this.isLoading = false;
                        return Toast.fail("USDT余额不足")
                    }
                } else {
                    this.isLoading = false;
                    return Toast.fail("请先绑定推荐人")
                }
            } else {
                this.isLoading = false;
                return Toast.fail("不可重复购买")
            }
            this.isLoading = false;
        },
        async getIdo() {
            return Toast.fail('即将开始')
        },
        // 获取可提取得FEDT数量
        // 提取FEDT代币
        // 获取收益数量
        getUserProfitInfo() {
            this.isLoading = true;
            this.$api.get('/app/token/getUserProfitInfo',{
               address: this.web3.userAddress,
               type: "FED"
            })
                .then((data) => {
                    if(data) {
                        // console.log('GET request response:', data);
                        this.isLoading = false;
                        // console.log('000',Number(data.payload.recommend_dividends).toFixed(3))
                        return this.income = data.payload;
                    }
                })
                .catch((error) => {
                    console.error('GET request error:', error)
                })
            this.isLoading = false;
        },
        // 提取收益
    },
}
</script>
<style>
@import './style/Ido.css';
</style>
